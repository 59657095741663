import { acceptHMRUpdate, defineStore } from 'pinia'
import type { AdminSettings } from '~/types/prisma'

export enum SettingIdByDescription {
  EnableLeaderboard = '1',
  EnableRaffles = '2',
  EnableTeamChanges = '3',
  CurrentYear = '4',
  SelectYear = '5',
  EnableAuctionItems = '6',
}

export function settingsArrayToRecord(settingsArray: AdminSettings[]) {
  return settingsArray.reduce((acc, curr) => ({
    ...acc,
    [curr.id]: curr,
  }), {} as Record<SettingIdByDescription, AdminSettings>)
}

export const useSettingsStore = defineStore('settings', () => {
  const year = ref(2024)
  const settings = ref<Record<SettingIdByDescription, AdminSettings>>({})

  function setSettings(settingsArray: AdminSettings[]) {
    const settingsRecord = settingsArrayToRecord(settingsArray)
    settings.value = settingsRecord
    year.value = settingsRecord[SettingIdByDescription.SelectYear]?.value ?? 2024
  }

  function setYear(yearFromSettings: number) {
    year.value = yearFromSettings
  }

  return {
    year,
    settings,
    setYear,
    setSettings,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useSettingsStore, import.meta.hot))
